'use client';

import {
  ApiCalls,
  EventTypes,
  UserEvents,
  useLogEvent
} from '@/app/hooks/useLogEvent';
import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useQuery } from '@tanstack/react-query';

const fetchCheckMIDExists = async ({
  partner,
  merchantID,
  successCallback,
  traceID,
  spanID,
  logUserEvent,
  logAPICall,
}: {
  partner: string;
  merchantID: string;
  successCallback: (data: any) => void;
  traceID: string;
  spanID: string;
  logUserEvent: (eventName: UserEvents, value?: string) => void;
  logAPICall: (requestName: string, traceId: string, spanId: string) => void;
}) => {
  if (!merchantID) {
    successCallback(false);
    return false;
  }
  
  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);
  const response = await fetch(
    `${partnerURLHost}/api/${partner}/validate/mid?partner=${partner}&mid=${merchantID}`,
    {
      method: 'Get',
      cache: 'no-store',
      headers: {
        'X-Datadog-Trace-Id': traceID,
        'X-Datadog-Parent-Id': spanID,
        'X-Datadog-Origin': 'local',
        'X-Datadog-Sampling-Priority': '1',
      },
    },
  );

  logAPICall(ApiCalls.VALIDATE_MID, traceID, spanID);

  const isMIDValid = response.status === 200;
  
  successCallback(isMIDValid);

  if (!isMIDValid) {
    logUserEvent(UserEvents.MERCHANT_ENTERED_INVALID_MID);
  }

  return isMIDValid;
}

export const useCheckMIDExists = (partner: string, sellerKey: string, successCallback: (data: any) => void) => {
  const logAPICall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const traceID = typeof window !== 'undefined' ? generateUniqueId() : '';
  const spanID = typeof window !== 'undefined' ? generateUniqueId() : '';

  const fetchCheckMIDExistsParams: {
    partner: string;
    merchantID: string;
    successCallback: (data: any) => void;
    traceID: string;
    spanID: string;
    logUserEvent: (eventName: UserEvents, value?: string) => void;
    logAPICall: (requestName: string, traceId: string, spanId: string) => void;
  } = {
    partner,
    merchantID: sellerKey,
    successCallback,
    traceID,
    spanID,
    logUserEvent,
    logAPICall,
  };

  const query = useQuery({
    queryKey: [`Validate MID ${sellerKey}`],
    queryFn: () => fetchCheckMIDExists(fetchCheckMIDExistsParams),
    retry: (failureCount) => {
      if (failureCount < 3) {
        return true;
      } else {
        logError(new Error(`MID validity check not ok`), traceID, spanID);
        return false;
      }
    },
  });

  return { ...query };
}
