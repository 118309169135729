'use client';

import { Button } from '@/app/components/Button';
import { Input } from '@/app/components/Input';
import { StepTracker } from '@/app/components/StepTracker';
import actions from '@/app/contexts/global/actions';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useFeatureFlags } from '@/app/hooks/useFeatureFlags';
import { useUpdateLead } from '@/app/hooks/useUpdateLead';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { Address } from '@/v2Components/Address';
import { Hero } from '@/v2Components/Hero';
import { Label } from '@/v2Components/Label';
import { PageLayout } from '@/v2Components/PageLayout';
import { PrepopApplicationFastTrackNotice } from '@/v2Components/PrepopApplicationFastTrack';
import { useEbaySeller } from '@/v2Hooks/useEbaySeller';
import { useCheckMIDExists } from '@/v2Hooks/useCheckMIDExists';
import { applicationRoutePaths } from '@/v2Lib/pageroutes';
import { useJourney } from '@/v2Hooks/useJourney';
import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { setBrazeUserEmail } from '../../braze-sdk';
import { GenericErrorModal } from './components/GenericErrorModal';
import InitialModal from './components/InitialModal/InitialModal';
import { Loader } from './components/Loader';
import { useLogEvent, SLIs, EventTypes } from '@/app/hooks/useLogEvent';
import { getJourneyFlowValue } from '@/v2Utils/journey/journeyUtils';
import { translateDropdownLabels } from '@/lib/utils/translateDropdownLabels';

export default function PartnerPrepopLandingPage() {
  const { getNextRoute, getCurrentStep } = useJourney(
    applicationRoutePaths.BUSINESS_DETAILS_PART_ONE_URL,
  );
  const params: { partner: string } = useParams();
  const updateLead = useUpdateLead(params.partner);
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const { state, dispatch } = useGlobalStore();
  const router = useRouter();
  const featureFlags = useFeatureFlags();
  const [movingNext, setMovingNext] = useState<boolean>(false);
  const [domLoading, setDomLoading] = useState<boolean>(true);
  const [invalidDataError, setInvalidDataError] = useState<boolean>(false);
  const [MIDExists, setMIDExists] = useState<boolean>(false);
  const [closeInitialModal, setCloseInitialModal] = useState<boolean>(false);
  const enableLeadService = featureFlags['enable-lead-service'];
  const enableMIDCheck = featureFlags['enable-mid-check'];
  const validatePartnerData = featureFlags['validate-partner-data'];
  const partnerSettings = getPartnerSettings(params.partner);
  const SELLER_KEY = 'external_id';
  const externalId = searchParams.get(SELLER_KEY) || '';
  const product = searchParams.get('product');
  const logSli = useLogEvent(EventTypes.SLI);
  const stateOptions = translateDropdownLabels(
    partnerSettings.shortStateOptions || [],
    t,
  );

  const handleCloseInitialModal = () => {
    dispatch(
      actions.updateOffersPayload({
        applicationComms: true,
      }),
    );
    setCloseInitialModal(true);
  };

  const {
    isLoading,
    data,
    isError,
    notFound: sellerNotFound,
  } = useEbaySeller((data) => {
    dispatch(actions.updateOffersPayload(data?.mappedData));
    dispatch(actions.updateOriginalData(data?.originalData));
    if (enableLeadService) {
      updateLead();
    }

    if (data?.originalData?.applicationData?.emailAddress) {
      setBrazeUserEmail(data.originalData.applicationData.emailAddress);
    }
  });

  const {
    isLoading: MIDCheckLoading,
    data: MIDCheckData,
    isError: MIDCheckError,
  } = useCheckMIDExists(params.partner, externalId, (MIDCheckData) => {
    setMIDExists(MIDCheckData);
  });

  const nextPage = () => {
    setMovingNext(true);
    router.push(getNextRoute());
  };

  useEffect(() => {
    dispatch(
      actions.setJourneyFlow({ journeyFlow: getJourneyFlowValue(product) }),
    );
  }, []);

  useEffect(() => {
    if (domLoading) {
      setDomLoading(false);
    }
  }, [state?.offersPayload]);

  useEffect(() => {
    if (validatePartnerData) {
      let missingData = false;
      const providedData = state?.partnerProvidedData?.applicationData;
      const address = providedData?.address;

      if (state?.partnerProvidedData && typeof address === 'object') {
        const hasState =
          Object(address).hasOwnProperty('stateOrProvince') &&
          !!address.stateOrProvince;
        const hasCity =
          Object(address).hasOwnProperty('city') && !!address.city;
        const hasZipCode =
          Object(address).hasOwnProperty('postalCode') && !!address.postalCode;
        const hasAddressLine1 =
          Object(address).hasOwnProperty('addressLine1') &&
          !!address.addressLine1;
        const hasEmail = !!providedData.emailAddress;
        const hasPhone = !!providedData.phoneNumber;
        if (
          !hasState ||
          !hasZipCode ||
          !hasCity ||
          !hasAddressLine1 ||
          !hasEmail ||
          !hasPhone
        ) {
          missingData = true;
        }
      } else if (state?.partnerProvidedData) {
        missingData = true;
      }

      if (missingData) {
        logSli(SLIs.MERCHANT_WITH_MISSING_PARTNER_DATA, 1);
      }
    }
  }, [state.partnerProvidedData]);

  const isInitialModalOpen = () => {
    const modalNotBlockedByMIDCheck = enableMIDCheck
      ? !MIDCheckError && MIDExists
      : true;

    return (
      !!externalId &&
      !isError &&
      modalNotBlockedByMIDCheck &&
      !closeInitialModal &&
      !sellerNotFound &&
      !invalidDataError
    );
  };

  const isGenericErrorModalOpen = () => {
    const showMIDCheckError =
      (enableMIDCheck ? MIDCheckError : false) ||
      (enableMIDCheck ? !MIDExists && !MIDCheckLoading && !isLoading : false);

    return (
      isError ||
      showMIDCheckError ||
      !externalId ||
      sellerNotFound ||
      invalidDataError
    );
  };

  const genericErrorModalHeader = () => {
    if (!externalId) {
      return t('pp-no-external-id-header');
    }
    if (sellerNotFound) {
      return t('pp-external-id-not-recognised-header');
    }
    if (invalidDataError) {
      return t('pp-external-id-missing-data-header');
    }
    if (enableMIDCheck && !MIDExists && !isError) {
      return t('pp-mid-not-found-in-merchant-profile-header');
    }
    return t('pp-unable-to-connect-to-seller-api-header');
  };

  const genericErrorModalText = () => {
    if (!externalId) {
      return t('pp-no-external-id-text');
    }
    if (sellerNotFound) {
      return t('pp-external-id-not-recognised-text');
    }
    if (invalidDataError) {
      return t('pp-external-id-missing-data-text');
    }
    if (enableMIDCheck && !MIDExists && !isError) {
      return t('pp-mid-not-found-in-merchant-profile-text').replace(
        '{contactPhoneNumber}',
        partnerSettings?.contactPhoneNumber ?? '',
      );
    }
    return t('pp-unable-to-connect-to-seller-api-text').replace(
      '{contactPhoneNumber}',
      partnerSettings?.contactPhoneNumber ?? '',
    );
  };

  const pageIsLoading = isLoading || (enableMIDCheck ? MIDCheckLoading : false);

  return (
    <>
      <StepTracker partner={params.partner} stepName="v2_LandingPage" />
      <PageLayout partner={params.partner}>
        <InitialModal
          open={isInitialModalOpen()}
          isLoading={pageIsLoading || domLoading}
          setCloseInitialModal={handleCloseInitialModal}
        />

        <GenericErrorModal
          open={isGenericErrorModalOpen()}
          header={genericErrorModalHeader()}
          text={genericErrorModalText()}
          showButton={true}
          buttonText={t('pp-generic-return-text')}
          buttonProps={{
            implementAsAnchor: true,
            mixpanelButtonText: 'unknown seller id return button',
            id: 'returnToPartner',
            href: partnerSettings.profileReturnLink,
          }}
        />

        <PageLayout.Left
          currentStep={1}
          numberOfSteps={5}
          heading={t('pp-application')}
          partner={params.partner}
          loading={pageIsLoading}
        >
          <div
            className={`border ${
              data?.mappedData?.businessName ? 'border-1' : 'border-0'
            } border-t-0 border-l-0 border-r-0 border-core-4  mb-4`}
          >
            <h2 className="text-core-8 text-lg mb-3 leading-5">
              {t('pp-bd-header')}
            </h2>
            <p className="text-core-7 text-sm mb-7">{t('pp-bd-intro-copy')}</p>
            <PrepopApplicationFastTrackNotice
              myWorldLink={partnerSettings.profileReturnLink!}
            />
            {data?.mappedData?.businessName &&
              state?.allowBusinessNameSelect !== false && (
                <>
                  <Label htmlFor="companyName">{t('pp-company-name')}</Label>
                  <Input
                    type="text"
                    id="companyName"
                    readonly={true}
                    value={data?.mappedData?.businessName || ''}
                  />
                </>
              )}
            {data?.mappedData?.businessType !== 'Unknown' && (
              <>
                <Label htmlFor="companyEntityType">
                  {t('pp-business-entity')}
                </Label>
                <Input
                  type="text"
                  id="companyEntityType"
                  readonly={true}
                  value={data?.mappedData?.businessType || ''}
                />
              </>
            )}
          </div>

          <Address
            legend="pp-registered-address-fieldset"
            fields={{
              line1: {
                id: 'addressLine1',
                label: 'pp-address-line-one',
                value: data?.mappedData?.businessAddress?.line1 || '',
              },
              line2: {
                id: 'addressLine2',
                label: 'pp-address-line-two',
                value: data?.mappedData?.businessAddress?.line2 || '',
              },
              city: {
                id: 'addressCity',
                label: 'pp-city',
                value: data?.mappedData?.businessAddress?.townCity || '',
              },
              state: {
                id: 'addressState',
                label: 'pp-state',
                stateOptions: stateOptions,
                value: data?.mappedData?.businessAddress?.state || '',
              },
              postcode: {
                id: 'addressZipcode',
                label: 'pp-zipcode',
                value: data?.mappedData?.businessAddress?.postcode || '',
              },
            }}
            readonly={true}
          />

          <div className="mt-14">
            <Button
              type="button"
              onclick={nextPage}
              mixpanelButtonText={'business detail 1 continue'}
              id="businessDetailStep1"
              isLoading={movingNext || pageIsLoading}
            >
              {!movingNext ? (
                t('pp-continue')
              ) : (
                <>
                  <div className="h-7 text-center w-full" aria-hidden>
                    <Loader />
                  </div>
                  <span className="sr-only">
                    {t('pp-loading-sr-only-message')}
                  </span>
                </>
              )}
            </Button>
          </div>
        </PageLayout.Left>

        <PageLayout.Right hiddenOnMobile={true}>
          <Hero />
        </PageLayout.Right>
      </PageLayout>
    </>
  );
}
