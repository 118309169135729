'use client';

import { t } from 'i18next';
import { FastTrackIcon } from '@/v2Components/FastTrackIcon';
import { ParsedText } from '@/app/components/ParsedText';

interface PrepopApplicationFastTrackProps {
  myWorldLink: string;
}

export const PrepopApplicationFastTrackNotice = ({
  myWorldLink,
}: PrepopApplicationFastTrackProps) => {
  return (
    <div className="bg-primary/5 rounded-md mb-8 group-[.loading]/main:animate-pulse group-[.loading]/main:!text-bg-primary/5 group-[.loading]/main:bg-core-4 text-sm">
      <div className="flex items-center px-6 pt-5 group-[.loading]/main:opacity-0">
        <FastTrackIcon />
        <div className="px-3">
          <h3 className="text-primary font-bold leading-7 ">
            {t('pp-prepop-heading')}
          </h3>
        </div>
      </div>
      <div className="text-core-7 leading-6 px-6 pt-2 pb-6 group-[.loading]/main:opacity-0 text-pretty">
        <ParsedText
          htmlString={t('pp-prepop-success').replaceAll(
            '{myWorldLink}',
            myWorldLink,
          )}
        />
      </div>
    </div>
  );
};
