import { JourneyFlow } from '@/v2Lib/journeyFlow';

export function getJourneyFlowValue(value: string | null): JourneyFlow | null {
  if (!value) {
    return JourneyFlow.BCA;
  }
  const normalizedValue = value.toUpperCase();
  const match = Object.values(JourneyFlow).find(
    (enumValue) => enumValue.toUpperCase() === normalizedValue,
  );
  return match ? (match as JourneyFlow) : JourneyFlow.BCA;
}
