export const FastTrackIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="12" className="fill-primary" />
      <svg
        x="4"
        y="4"
        width="16"
        height="16"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="flashlight-line">
          <path
            className="fill-core-1"
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2487 5.25H7.58203V0L2.33203 8.75H6.41536V14L12.2487 5.25ZM6.41493 4.21094V6.41594H10.0683L7.58159 10.1458V7.5826H4.39193L6.41493 4.21094Z"
          />
        </g>
      </svg>      
    </svg>
  );
};
