export const PersonalDetailsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 10C15.9999 12.2091 14.209 14 11.9999 14C9.79077 14 7.99991 12.2091 7.99991 10C7.99991 7.79086 9.79077 6 11.9999 6C14.209 6 15.9999 7.79086 15.9999 10ZM13.9999 10C13.9999 11.1046 13.1045 12 11.9999 12C10.8953 12 9.99991 11.1046 9.99991 10C9.99991 8.89543 10.8953 8 11.9999 8C13.1045 8 13.9999 8.89543 13.9999 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM16.596 19.7396C15.2508 20.5402 13.6791 21 12 21C10.3209 21 8.74912 20.5402 7.40384 19.7395C8.14682 18.1768 9.85264 17 11.9999 17C14.1472 17 15.8531 18.1768 16.596 19.7396ZM18.2161 18.5085C17.0503 16.424 14.7042 15 11.9999 15C9.29567 15 6.94959 16.4239 5.78377 18.5084C4.06849 16.8696 3 14.5596 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 14.5596 19.9315 16.8697 18.2161 18.5085Z"
        fill="currentColor"
      />
    </svg>
  );
};
