'use client';

import Select, { SingleValue } from 'react-select';

interface SelectProps {
  id: string;
  labelledById: string;
  options: { label: string; value: string }[];
  value?: string;
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    action?: { name: string },
  ) => void;
  placeholder: string;
  disabled?: boolean;
  required?: boolean;
  errorMessage?: string;
}

const SelectWrapper = ({
  id,
  labelledById,
  options,
  value,
  handleChange,
  placeholder,
  disabled,
  required,
  errorMessage,
}: SelectProps) => {
  const handleSelectChange = (
    newValue: SingleValue<{ value: string; label: string }>,
  ) => {
    if (newValue) {
      const event = {
        detail: { target: { id, value: newValue.value } },
      };
      handleChange(event);
    } else {
      const event = {
        detail: { target: { id, value: '' } },
      };
      handleChange(event);
    }
  };

  const errorStylesFromOldDropdown =
    "data-[error='true']:!border-error-1 data-[error='true']:!bg-error-2/50 [&_input]:data-[error='true']:placeholder:!text-core-8 data-[error='true']:focus-within:!shadow-error-1 data-[error='true']:focus-within:!shadow-[0_0_0_1px_rgba(0,116,217,0.2)]";

  const customClassNames = {
    container: () => `peer group-[.loading]/main:opacity-0`,
    control: () =>
      `!border-core-5 h-[42px] focus-within:!shadow-[0_0_0_2px_rgba(0,0,0,1)] focus-within:!border-core-1  ${errorStylesFromOldDropdown} group flexible-select-control`,
    dropdownIndicator: () => 'text-core-5',
    placeholder: () => '!text-core-6 group-data-[error=true]:!text-core-7 pl-2',
  };

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 50,
    }),
    control: (provided: any) => ({
      ...provided,
      backgroundColor: disabled ? 'rgb(var(--core-2))' : '#ffffff',
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: disabled ? 'rgb(var(--core-7))' : 'rgb(var(--core-8))',
      paddingLeft: '0.5rem',
    }),
  };

  return (
    <div className="relative group-[.loading]/main:text-core-3 group-[.loading]/main:bg-core-3 group-[.loading]/main:border-core-3 group-[.loading]/main:!placeholder-core-3 group-[.loading]/main:select-none">
      <Select
        id={id}
        instanceId={id}
        name={id}
        options={options}
        value={options.find((option) => option.value === value)}
        onChange={handleSelectChange}
        placeholder={placeholder}
        aria-labelledby={labelledById}
        isSearchable={false}
        isDisabled={disabled}
        required={required}
        styles={customStyles}
        classNames={customClassNames}
        className="react-flexible-select"
      />
      <div className="hidden peer-data-[error=true]:block text-error-1 text-sm pt-1 pr-1 w-full absolute leading-5">
        {errorMessage}
      </div>
    </div>
  );
};

export { SelectWrapper as Select };
