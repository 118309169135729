'use client';

import { Input } from '@/app/components/Input';
import { useTranslation } from '@/app/i18n/client';
import { Label } from '@/v2Components/Label';
import { FlexibleSelect } from '../FlexibleSelect';

interface AddressProps {
  legend: string;
  fields: {
    line1: AddressFieldDetails;
    line2: AddressFieldDetails;
    city: AddressFieldDetails;
    state: AddressFieldDetails;
    postcode: AddressFieldDetails;
  };
  handleChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  readonly?: boolean;
}

interface AddressFieldDetails {
  id: string;
  label: string;
  value: string;
  stateOptions?: { label: string; value: string }[];
  placeholder?: string;
}

const Address = ({
  legend,
  fields,
  handleChange = () => {},
  readonly = false,
}: AddressProps) => {
  const { t } = useTranslation();

  return (
    <fieldset className="pt-8">
      <legend className="text-sm text-core-8 group-[.loading]/main:animate-pulse group-[.loading]/main:!text-core-4 group-[.loading]/main:bg-core-4">
        {t(legend)}
      </legend>
      <div className="grid grid-cols-1 tablet:grid-cols-2 gap-x-8">
        <div>
          <Label htmlFor={fields.line1.id}>{t(fields.line1.label)}</Label>
          <Input
            type="text"
            id={fields.line1.id}
            value={fields.line1.value}
            onChange={handleChange}
            required
            readonly={readonly}
            errorMessage={t('pp-required-field-error')}
            placeholder={
              fields.line1.placeholder && t(fields.line1.placeholder)
            }
          />
        </div>
        <div>
          <Label htmlFor={fields.line2.id}>{t(fields.line2.label)}</Label>
          <Input
            type="text"
            id={fields.line2.id}
            value={fields.line2.value}
            onChange={handleChange}
            readonly={readonly}
            placeholder={
              fields.line2.placeholder && t(fields.line2.placeholder)
            }
          />
        </div>
        <div>
          <Label htmlFor={fields.city.id}>{t(fields.city.label)}</Label>
          <Input
            type="text"
            id={fields.city.id}
            value={fields.city.value}
            onChange={handleChange}
            required
            readonly={readonly}
            errorMessage={t('pp-required-field-error')}
            placeholder={fields.city.placeholder && t(fields.city.placeholder)}
          />
        </div>
        <div>
          <Label id="state-label" htmlFor={fields.state.id}>
            {t(fields.state.label)}
          </Label>
          <FlexibleSelect
            id={fields.state.id}
            labelledById="state-label"
            placeholder={
              (fields.state.placeholder && t(fields.state.placeholder)) ||
              t('pp-dropdown-default-placeholder')
            }
            handleChange={handleChange}
            options={fields.state.stateOptions || []}
            value={fields.state.value}
            required
            errorMessage={t('pp-required-field-error')}
            key={`address-state-${fields.state.value}`}
            disabled={readonly}
          />
        </div>
        <div>
          <Label htmlFor={fields.postcode.id}>{t(fields.postcode.label)}</Label>
          <Input
            type="text"
            id={fields.postcode.id}
            value={fields.postcode.value}
            onChange={handleChange}
            required
            readonly={readonly}
            errorMessage={t('pp-required-field-error')}
            placeholder={
              fields.postcode.placeholder && t(fields.postcode.placeholder)
            }
          />
        </div>
      </div>
    </fieldset>
  );
};

export { Address };
