export const BriefcaseIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3C8 2.44772 8.44772 2 9 2H13C13.5523 2 14 2.44772 14 3V4H8V3ZM6 4V3C6 1.34315 7.34315 0 9 0H13C14.6569 0 16 1.34315 16 3V4H19C20.6569 4 22 5.34315 22 7V17C22 18.6569 20.6569 20 19 20H18C18 20.5523 17.5523 21 17 21C16.4477 21 16 20.5523 16 20H6C6 20.5523 5.55228 21 5 21C4.44772 21 4 20.5523 4 20H3C1.34315 20 0 18.6569 0 17V7C0 5.34315 1.34315 4 3 4H6ZM18 18H19C19.5523 18 20 17.5523 20 17V7C20 6.44772 19.5523 6 19 6H18V18ZM16 6V18H6V6H16ZM3 6H4V18H3C2.44772 18 2 17.5523 2 17V7C2 6.44772 2.44772 6 3 6Z"
        fill="currentColor"
      />
    </svg>
  );
};
