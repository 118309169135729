import { Button } from '@/app/components/Button';
import { ParsedText } from '@/app/components/ParsedText';
import { BriefcaseIcon } from '@/app/components/icons/Briefcase';
import { ExternalLinkIcon } from '@/app/components/icons/ExternalLink';
import { PersonalDetailsIcon } from '@/app/components/icons/PersonalDetails';
import { useGlobalStore } from '@/app/contexts/global/globalContext';
import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import Image from 'next/image';
import Modal from 'react-modal';
import './InitialModalCSS.css';

Modal.setAppElement('#appRoot');

const InitialModal = ({
  open,
  isLoading,
  setCloseInitialModal,
}: {
  open: boolean;
  isLoading: boolean;
  setCloseInitialModal: () => void;
}) => {
  const { t } = useTranslation();
  const { state } = useGlobalStore();
  const partner = usePartner();
  const partnerSettings = getPartnerSettings(partner);

  const skeletonCSS =
    'group-[.loading]:animate-pulse group-[.loading]:!text-core-4 group-[.loading]:!bg-core-4 group-[.loading]:pointer-events-none group-[.loading]:rounded-lg';

  const acceptAndContinue = () => {
    setCloseInitialModal();
  };

  const cleanUpOnClose = () => {
    document.body.classList.remove('ReactModal__Body--open');
    document.getElementById('appRoot')?.removeAttribute('aria-hidden');
  };

  return (
    <Modal
      isOpen={open}
      className="introModal"
      overlayClassName="introOverlay"
      contentLabel="Contracts"
      onRequestClose={acceptAndContinue}
      onAfterClose={cleanUpOnClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div
        className={`max-w-3xl mx-auto pt-6 lmobile:pt-10 flex-1 flex flex-col top-0 group ${
          isLoading ? 'loading' : ''
        }`}
      >
        <div className="px-4 mobile:px-6 lmobile:px-10">
          <Image
            src={`${getEnvVar('NEXT_PUBLIC_CDN')}${partnerSettings.imagesPath}${
              partnerSettings.images!.logo
            }`}
            alt="eBay and Liberis "
            width={185}
            height={25}
            className="w-[125px] mobile:w-[140px] mb-6 "
          />
        </div>
        <div className="flex-1 overflow-auto px-4 mobile:px-6 lmobile:px-10">
          <h2 className={`mb-4 mobile:mb-6 text-lg ${skeletonCSS}`}>
            {t('pp-bd1-modal-header')}
          </h2>
          <p className="mb-4 mobile:mb-6 ">
            <span className={`text-sm font-medium ${skeletonCSS}`}>
              {t('pp-bd1-seller-account-label')}
            </span>
            <span className={`block text-sm mb-4 text-core-7 ${skeletonCSS}`}>
              <a href={partnerSettings.profileReturnLink} target="_blank">
                {state?.offersPayload?.company?.legal_name
                  ? state.offersPayload.company.legal_name
                  : t('pp-bd1-generic-profile-link-text')}
                <span className="h-4 w-4 inline-block top-[2px] ml-2 relative">
                  <ExternalLinkIcon />
                </span>
              </a>
            </span>
            <span className={`block text-core-7 text-sm  ${skeletonCSS}`}>
              {t('pp-bd1-share-with-liberis-copy')}
            </span>
          </p>
          <ul className="mb-4 mobile:mb-8">
            <li className="mb-4 mobile:mb-8 mr-4">
              <span
                className={`inline-block h-5 w-5 mr-5 relative top-[3px] ${skeletonCSS}`}
              >
                {!isLoading && <BriefcaseIcon />}
              </span>
              <span className={`mb-4 text-sm font-medium  ${skeletonCSS}`}>
                {t('pp-bd1-business-details-label')}
              </span>
              <div
                className={`block text-core-7 text-sm ml-10 text-balance mt-2 ${skeletonCSS}`}
              >
                {t('pp-bd1-business-details-data-list')}
              </div>
            </li>
            <li className=" mr-4">
              <span
                className={`inline-block h-5 w-5 mr-5 relative top-[2px] ${skeletonCSS}`}
              >
                {!isLoading && <PersonalDetailsIcon />}
              </span>
              <span className={`text-sm font-medium ${skeletonCSS}`}>
                {t('pp-bd1-presonal-details-label')}
              </span>
              <div
                className={`block text-core-7 text-sm ml-10 text-balance mt-2 ${skeletonCSS}`}
              >
                {t('pp-bd1-personal-details-data-list')}
              </div>
            </li>
          </ul>
          <p
            className={`text-core-7 mb-8 text-sm leading-6 ${skeletonCSS} text-pretty`}
          >
            <ParsedText
              htmlString={t('pp-bd1-liberis-consent')
                .replace(
                  '{terms_and_conditions}',
                  partnerSettings['terms-and-conditions-url'] ?? '',
                )
                .replace(
                  '{privacy_policy}',
                  partnerSettings['privacy-policy-url'] ?? '',
                )}
            />
          </p>
          <div className={`w-full text-center ${skeletonCSS}`}>
            <Button
              onclick={acceptAndContinue}
              type="button"
              id="landingPageModalContinueButton"
              mixpanelButtonText="Landing page modal clicked"
              className={isLoading ? 'opacity-0 h-12' : ''}
            >
              {t('pp-bd1-modal-continue-button-label')}
            </Button>
          </div>
          <div className="w-full text-center mb-4">
            <Button
              href={partnerSettings.profileReturnLink}
              id="landingPageModalUpdateButton"
              mixpanelButtonText="Update information modal button"
              implementAsAnchor={true}
              className={`bg-transparent text-center mx-auto inline-block relative my-4 text-core-7 ${skeletonCSS} hover:text-core-8 hover:underline`}
              target="_blank"
            >
              {t('pp-bd1-modal-update-info-button-label')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default InitialModal;
