export const ExternalLinkIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5858 3L7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711C7.68342 9.09763 8.31658 9.09763 8.70711 8.70711L13 4.41421V6C13 6.55228 13.4477 7 14 7C14.5523 7 15 6.55228 15 6V2.00069V2C15 1.999 15 1.998 15 1.997C14.9992 1.74208 14.9016 1.48739 14.7071 1.29289C14.6112 1.19702 14.5007 1.12468 14.3828 1.07588C14.2649 1.02699 14.1356 1 14 1H13.9998H10C9.44771 1 9 1.44772 9 2C9 2.55228 9.44771 3 10 3H11.5858ZM1 5.5C1 4.11929 2.11929 3 3.5 3H6C6.55228 3 7 3.44772 7 4C7 4.55228 6.55228 5 6 5H3.5C3.22386 5 3 5.22386 3 5.5V12.5C3 12.7761 3.22386 13 3.5 13H10.5C10.7761 13 11 12.7761 11 12.5V10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V12.5C13 13.8807 11.8807 15 10.5 15H3.5C2.11929 15 1 13.8807 1 12.5V5.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
