import {
  ApiCalls,
  EventTypes,
  UserEvents,
  useLogEvent,
} from '@/app/hooks/useLogEvent';
import { generateUniqueId } from '@/lib/utils/generateUniqueId';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export const fetchEbaySellerDetails = async ({
  partner,
  externalId,
  traceId,
  spanId,
  logAPICall,
  logUserEvent,
  successCallback,
  setNotFound,
}: {
  partner: string;
  externalId: string;
  traceId: string;
  spanId: string;
  logUserEvent: (eventName: UserEvents, value?: string) => void;
  logAPICall: (requestName: string, traceId: string, spanId: string) => void;
  successCallback: (data: any) => void;
  setNotFound: (notFound: boolean) => void;
}) => {
  const host_url = getEnvVar('NEXT_PUBLIC_HOST_URL');
  const partnerURLHost = host_url?.replace('{partner}', partner);

  if (!externalId) {
    logUserEvent(UserEvents.MISSING_EXTERNAL_ID);
    return {};
  }

  const res = await fetch(
    `${partnerURLHost}/api/${partner}/ebaySeller/${externalId}`,
    {
      method: 'GET',
      cache: 'no-store',
    },
  );
  if (!res.ok) {
    if (res.status === 404) {
      setNotFound(true);
      return {};
    } else {
      throw new Error(`Response from seller API was not ok: ${res.status} ${res.statusText}`);
    }
  } else {
    logAPICall(ApiCalls.FETCH_PARTNER_DATA, traceId, spanId);
  }
  const data = await res.json();
  successCallback(data);
  return data;
};

export const useEbaySeller = (successCallback: (data: any) => void) => {
  const [notFound, setNotFound] = useState(false);
  const SELLER_KEY = 'external_id';
  const params: { partner: string } = useParams();
  const searchParams = useSearchParams();
  const externalId = searchParams.get(SELLER_KEY) || '';

  const logAPICall = useLogEvent(EventTypes.API_CALL);
  const logError = useLogEvent(EventTypes.ERROR);
  const logUserEvent = useLogEvent(EventTypes.USER_EVENT);

  const traceId = typeof window !== 'undefined' ? generateUniqueId() : '';
  const spanId = typeof window !== 'undefined' ? generateUniqueId() : '';

  const fetchEbaySellerDetailsParams: {
    partner: string;
    traceId: string;
    spanId: string;
    logAPICall: (requestName: string, traceId: string, spanId: string) => void;
    logUserEvent: (eventName: UserEvents, value?: string) => void;
    externalId: string;
    successCallback: (data: any) => void;
    setNotFound: (notFound: boolean) => void;
  } = {
    partner: params.partner,
    traceId,
    spanId,
    logUserEvent,
    logAPICall,
    externalId,
    successCallback: successCallback,
    setNotFound,
  };

  useEffect(() => {
    if (notFound) {
      logUserEvent(UserEvents.EXTERNAL_ID_NOT_RECOGNISED);
    } else {
      logUserEvent(UserEvents.EXTERNAL_ID_RECOGNISED);
    }
  }, [notFound]);

  const query = useQuery({
    queryKey: ['Seller details'],
    queryFn: () => fetchEbaySellerDetails(fetchEbaySellerDetailsParams),
    retry: (failureCount) => {
      if (failureCount < 3) {
        return true;
      } else {
        logError(new Error(`Seller API response not ok`), traceId, spanId);
        return false;
      }
    },
  });

  return { ...query, notFound };
};
